import { RouteRecordRaw } from 'vue-router';
import { routePath } from '/@/constant/router';

const { iFrameEam } = routePath;

export const iFrameEamRoute: RouteRecordRaw = {
    path: iFrameEam.index,
    name: 'iFrameEam.index',
    component: () => import('/@/views/eam/index.vue'),
    meta: {
        title: '资产管理',
        roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
        // isHeadNav: false,
    },
    redirect: iFrameEam.dashboard,
    children: [
        // 仪表盘
        {
            path: iFrameEam.dashboard,
            name: 'iFrameEam.dashboard',
            component: () => import('/@/views/eam/dashboard/index.vue'),
            meta: {
                title: '仪表盘',
                roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
            },
        },
        {
            path: iFrameEam.smart.index,
            name: 'iFrameEam.smart.index',
            component: () => import('/@/views/eam/smart/index.vue'),
            meta: { title: '智能设备', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
            children: [
                {
                    path: iFrameEam.smart.list,
                    name: 'iFrameEam.smart.list',
                    component: () => import('/@/views/eam/smart/list/index.vue'),
                    meta: { title: '设备列表', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.smart.statistic,
                    name: 'iFrameEam.smart.statistic',
                    component: () => import('/@/views/eam/smart/deviceStatistic/index.vue'),
                    meta: { title: '设备统计', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.smart.detail,
                    name: 'iFrameEam.smart.detail',
                    component: () => import('/@/views/eam/smart/detail/index.vue'),
                    meta: { title: '历史报告' },
                },
            ],
        },
        // 供应商
        {
            path: iFrameEam.provider,
            name: 'iFrameEam.provider',
            component: () => import('/@/views/eam/provider/index.vue'),
            meta: { title: '供应商管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
        },
        // 部门
        {
            path: iFrameEam.department,
            name: 'iFrameEam.department',
            component: () => import('/@/views/eam/department/index.vue'),
            meta: { title: '部门管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
        },
        // 设备台账
        {
            path: iFrameEam.device_extra,
            name: 'iFrameEam.device_extra',
            component: () => import('/@/views/eam/deviceExtra/index.vue'),
            meta: { title: '资产台账', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
        },
        // 维保计划
        {
            path: iFrameEam.main_plan.index,
            name: 'iFrameEam.main_plan.index',
            component: () => import('/@/views/eam/maintainPlan/index.vue'),
            redirect: iFrameEam.main_plan.send,
            meta: { title: '维保计划', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
            children: [
                {
                    path: iFrameEam.main_plan.send,
                    name: 'iFrameEam.main_plan.send',
                    component: () => import('/@/views/eam/maintainPlan/send.vue'),
                    meta: { title: '我发起的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.main_plan.worker,
                    name: 'iFrameEam.main_plan.worker',
                    component: () => import('/@/views/eam/maintainPlan/work.vue'),
                    meta: { title: '我执行的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME', 'GATEWAY'] },
                },
                {
                    path: iFrameEam.main_plan.remind,
                    name: 'iFrameEam.main_plan.remind',
                    component: () => import('/@/views/eam/maintainPlan/remind.vue'),
                    meta: { title: '维保提醒', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME', 'GATEWAY'] },
                },
            ],
        },
        // 设备巡检
        {
            path: iFrameEam.device_inspection.index,
            name: 'iFrameEam.device_inspection.index',
            component: () => import('/@/views/eam/deviceInspection/index.vue'),
            redirect: iFrameEam.device_inspection.send,
            meta: { title: '设备巡检', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
            children: [
                {
                    path: iFrameEam.device_inspection.send,
                    name: 'iFrameEam.device_inspection.send',
                    component: () => import('/@/views/eam/deviceInspection/component/send.vue'),
                    meta: { title: '我发起的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.device_inspection.worker,
                    name: 'iFrameEam.device_inspection.worker',
                    component: () => import('/@/views/eam/deviceInspection/component/work.vue'),
                    meta: { title: '我执行的', roles: ['MANAGER', 'DEPLOYER', 'RUNTIME'] },
                },
            ],
        },
        // 工单
        {
            path: iFrameEam.work_order.index,
            name: 'iFrameEam.work_order.index',
            component: () => import('/@/views/eam/workOrder/index.vue'),
            meta: { title: '工单', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
            children: [
                {
                    path: iFrameEam.work_order.send,
                    name: 'iFrameEam.work_order.send',
                    component: () => import('/@/views/eam/workOrder/send.vue'),
                    meta: { title: '我发起的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.work_order.worker,
                    name: 'iFrameEam.work_order.worker',
                    component: () => import('/@/views/eam/workOrder/work.vue'),
                    meta: { title: '我执行的', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
            ],
        },
        // 库存管理
        {
            path: iFrameEam.inventory.index,
            name: 'iFrameEam.inventory.index',
            component: () => import('/@/views/eam/inventory/index.vue'),
            meta: { title: '库存管理', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
            children: [
                {
                    path: iFrameEam.inventory.category,
                    name: 'iFrameEam.inventory.category',
                    component: () => import('/@/views/eam/inventory/category.vue'),
                    meta: { title: '库存类目', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
                {
                    path: iFrameEam.inventory.outcome,
                    name: 'iFrameEam.inventory.outcome',
                    component: () => import('/@/views/eam/inventory/outcomeRecord.vue'),
                    meta: { title: '出库记录', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
                },
            ],
        },
        // 工作日报
        {
            path: iFrameEam.report,
            name: 'iFrameEam.report',
            component: () => import('/@/views/eam/report/index.vue'),
            meta: { title: '工作日报', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
        },
        // 站内信列表
        {
            path: iFrameEam.notify,
            name: 'iFrameEam.notify',
            component: () => import('/@/views/eam/notify/index.vue'),
            meta: { title: '站内信', roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'] },
        },
    ],
};
