import qs from 'qs';
import { del, get, postJSON, put } from '/@/utils/http';
import { omitDirtyField } from '/@/utils/tools/map';

/**
 * @description 用户登录
 * @param user
 */
export const loginAPI = async (user: Partial<User>) =>
	await postJSON<User>({
		url: '/api/deploy/authentication/login',
		data: omitDirtyField(user),
	});

/**
 * @description 判断用户是否登录
 */
export const isLoginAPI = async () => await get<boolean>({ url: '/api/deploy/authentication/islogined' });

/**
 * @description 用户退出登录
 */
export const logoutAPI = async () => await get<any>({ url: '/api/deploy/authentication/logout' });

export const getUserListApi = async (params: Partial<UserListAPIParam>, successCallback?: Function, failCallback?: Function) =>
	await get<Paging<User>>(
		{
			url: '/api/deploy/user/page',
			params: omitDirtyField(params),
		},
		successCallback,
		failCallback
	);

/**
 * @description 修改类型和权限
 * @param param
 * @returns
 */
export const editAuthAndTypeAPI = async (param: EditAuthAndTypeParam) => {
	return await put<any>({ url: '/api/deploy/user', data: qs.stringify(omitDirtyField(param)) });
};

/**
 * @description 设置用户菜单权限
 * @param param
 * @returns
 */
export const setUserMenuAuthAPI = async (param: SetUserMenuAuthParam) => {
	return await postJSON<any>({ url: '/api/deploy/user-menu-relation', data: omitDirtyField(param) });
};

/**
 * @description 添加用户
 * @param param
 * @param successCallback
 * @returns
 */
export const addUserAPI = async (param: User, successCallback?: Function) => {
	return await postJSON<number>({ url: '/api/deploy/user', data: param }, successCallback);
};

/**
 * @description 根据id删除用户
 * @param id
 * @returns
 */
export const deleteUserAPI = async (id: string) => {
	return await del<any>({ url: `/api/deploy/user/${id}` });
};

/**
 * @description 修改密码
 * @param data
 * @returns
 */
export const updatePasswordAPI = async (data: Partial<UpdatePasswordParam>) => {
	return await put<any>({ url: '/api/deploy/user/updatePassword', data: qs.stringify(omitDirtyField(data)) });
};

/**
 * @description 用户统计
 * @returns
 */
export const userStatisticsAPI = async () => {
	return await get<any>({ url: '/api/runtime/user/count' });
};

/**
 * 回传cookie
 * @param key 
 * @param value 
 */
export const authenticationApi = async (key: string, value: string) => await get({ url: `/api/deploy/authentication/reset-cookie?name=${key}&value=${value}` })