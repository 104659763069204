import { getSystemStatusAPI } from '/@/api/system';
import { LocalStorage } from '/@/utils/localStorage';
import { routePath } from '/@/constant/router';
import { engineRoute } from '/@/router/modules/engine';
import { fileManageRoute } from '/@/router/modules/fileManagent';
import { mapRoute } from './modules/map';
import { linkageRoute } from '/@/router/modules/linkage';
import { platformEventRoute } from '/@/router/modules/platformEvent';
import { eamRoute } from '/@/router/modules/eam';
import { iFrameEamRoute } from '/@/router/modules/frameEam';
import { tvWallRoute } from '/@/router/modules/tvWall';
import { RouteRecordRaw } from 'vue-router';

const route_base_path = process.env.VUE_APP_ROUTE_BASE_URL;

async function isGateway() {
	const systemStatus = LocalStorage.get<arrayDataType>('SYSTEM_STATUS');
	if (systemStatus) {
		return systemStatus.includes('gateway');
	}
	const res = await getSystemStatusAPI();
	if (res.success) {
		return res.data.activeProfiles.includes('gateway');
	}
	return false;
}

const gatewayStatus = await isGateway();

export const routes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'adapt_layout',
		component: () => import('/@/views/layout/adapterLayout.vue'),
		redirect: gatewayStatus ? '/platform/engine/driver/list' : '/home',
		children: [
			{
				path: "/xxxx",
				name: 'xxxx',
				component: () => import('/@/views/eam/index.vue'),
			},
			iFrameEamRoute,
			// 首页
			{
				path: routePath.home,
				name: 'home',
				component: () => import('/@/views/home/index.vue'),
				meta: {
					title: '首页',
					roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
					isHeadNav: true,
				},
			},
			// 登录页
			{
				path: routePath.login,
				name: 'login',
				component: () => import('/@/views/login/index.vue'),
				meta: {
					title: '登录页',
					roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
				},
			},
			// 3d可视化
			{
				path: routePath.three_view,
				name: 'three_view',
				component: () => import('/@/views/view3D/index.vue'),
				meta: {
					title: '3D',
					roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
				},
			},
			// 授权管理页
			{
				path: routePath.license,
				name: 'license',
				component: () => import('/@/views/license/index.vue'),
				meta: {
					title: '授权管理',
					roles: ['DEPLOYER', 'MANAGER', 'RUNTIME'],
				},
			},
			// 电视墙展示页
			{
				path: routePath.tv_wall.display,
				name: 'tv_wall.display',
				component: () => import('/@/views/tvWall/tvDisplay/index.vue'),
				meta: {
					title: '电视墙',
				},
			},
			{
				path: routePath.base_path,
				name: `${route_base_path}`,
				component: () => import('/@/views/layout/platformLayout.vue'),
				redirect: routePath.map,
				meta: {
					title: '平台',
				},
				children: [
					// 系统集成
					mapRoute,
					// 平台事件
					platformEventRoute,
					// 场景联动
					linkageRoute,
					// 文件管理
					fileManageRoute,
					// 工程实施
					engineRoute,
					// 资产管理
					eamRoute,
					// 电视墙
					tvWallRoute,
				],
			},
		],
	},
];
