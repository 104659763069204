import { del, get, postJSON, put, putJSON } from '/@/utils/http';
import qs from 'qs';

/**
 * @description 8091系统实时状态
 * @returns
 */
const getDeploySystemStatusAPI = async () => {
	return await get<any>({ url: '/api/deploy/system/status' });
};

/**
 * @description 8092系统实时状态
 * @returns
 */
const getEngineSystemStatusAPI = async () => {
	return await get<any>({ url: '/api/engine/system/status' });
};

/**
 * @description 8093系统实时状态
 * @returns
 */
const getLinkSystemStatusAPI = async () => {
	return await get<any>({ url: '/api/link/system/status' });
};

/**
 * @description 8094系统实时状态
 * @returns
 */
const getRuntimeSystemStatusAPI = async () => {
	return await get<any>({ url: '/api/runtime/system/status' });
};

/**
 * @description 设置异常处理器的日志级别
 * @param api
 * @param param
 * @returns
 */
const putExceptionResolverLevelAPI = async (api: any, param: object) => {
	return await put<any>({ url: api + '/exceptionResolverLevel', data: qs.stringify(param) });
};

/**
 * @description 设置任意日志的级别
 * @param api
 * @param param
 * @returns
 */
const putLoggerLevelAPI = async (api: any, param: object) => {
	return await put<any>({ url: api + '/level', data: qs.stringify(param) });
};

/**
 * @description 查询是否需要重载缓存
 * @returns
 */
const getReloadingRequiredAPI = async () => {
	return await get<any>({ url: '/api/engine/system-manage/cache-all/reloading-required' });
};

/**
 * @description 重载缓存
 * @param cacheName
 * @returns
 */
const ReloadCacheAPI = async (cacheName: string) => {
	return await postJSON<any>({ url: '/api/engine/system-manage/' + cacheName + '/reload' });
};

/**
 * @description 从数据库查询系统公开配置
 * 低频率调用，从数据库中拿
 * 调用一次，缓存中的数据刷新
 * @returns
 */
const getDBSystemPublicConfigAPI = async () => await get<PublicConfig>({ url: '/api/deploy/system-setting/public-config-db' });

/**
 * @description 从缓存查询系统公开配置
 * 高频率调用用缓存，数据具有滞后性
 * @returns
 */
const getCacheSystemPublicConfigAPI = async () => {
	return await get<any>({ url: '/api/deploy/system/status/public-config-cache' });
};

/**
 * @description 修改系统公开配置
 * @param data
 * @returns
 */
const putSystemPublicConfigAPI = async (data: PublicConfig) => {
	return await putJSON<any>({ url: '/api/deploy/system-setting/public-config', data });
};

/**
 * @description 修改首页配置
 * @param data
 * @returns
 */
const putSystemHomeConfigAPI = async (data: any) => {
	return await putJSON<any>({ url: '/api/deploy/system-setting/home-config', data });
};

/**
 * @description 从数据库查询首页配置
 * @returns
 */
const getDBSystemHomeConfigAPI = async () => {
	return await get<any>({ url: '/api/deploy/system-setting/home-config-db' });
};

/**
 * @description 从缓存查询首页配置
 * @returns
 */
const getCacheSystemHomeConfigAPI = async () => {
	return await get<PublicConfig>({ url: '/api/deploy/system/status/home-config-cache' });
};

/**
 * @description 查询全部子系统
 * @param successCallback
 * @param failCallback
 * @returns
 */
const querySubSysListAPI = async (successCallback?: Function, failCallback?: Function) => {
	return await get<System[]>({ url: '/api/deploy/sub-sys/list' }, successCallback, failCallback);
};

/**
 * @description 修改某个子系统
 * @param data
 * @returns
 */
const updateSubSysAPI = async (data: System) => {
	return await putJSON<boolean>({ url: '/api/deploy/sub-sys', data });
};

/**
 * @description 新增某个子系统
 * @param data
 * @returns
 */
const addSubSysAPI = async (data: System) => {
	return await postJSON<boolean>({ url: '/api/deploy/sub-sys', data });
};
/**
 * @description 删除某个子系统
 * @param id
 * @returns
 */
const delSubSysByIdAPI = async (id: number) => {
	return await del<boolean>({ url: `/api/deploy/sub-sys/${id}` });
};

/**
 * @description 查询视频播放器
 * @param successCallback
 * @param failCallback
 * @returns
 */
const getVideoPlayerStatus = async (successCallback?: Function, failCallback?: Function) => {
	return await get<VideoPlayerType>({ url: '/api/deploy/system/status/video-player' }, successCallback, failCallback);
};

/* *
 * @description 获取系统状态
 */
const getSystemStatusAPI = async () => await get<SystemStatus>({ url: '/api/link/system/status' });

export {
	getDeploySystemStatusAPI,
	getEngineSystemStatusAPI,
	getLinkSystemStatusAPI,
	getRuntimeSystemStatusAPI,
	putExceptionResolverLevelAPI,
	putLoggerLevelAPI,
	getReloadingRequiredAPI,
	ReloadCacheAPI,
	getDBSystemPublicConfigAPI,
	getCacheSystemPublicConfigAPI,
	putSystemPublicConfigAPI,
	querySubSysListAPI,
	putSystemHomeConfigAPI,
	getDBSystemHomeConfigAPI,
	getCacheSystemHomeConfigAPI,
	updateSubSysAPI,
	addSubSysAPI,
	delSubSysByIdAPI,
	getVideoPlayerStatus,
	getSystemStatusAPI,
};
